import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import RenderResponse from "./RenderResponse";

export default function Querries({ active, userId, change }) {
  let { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { userId },
  });
  const [loadSheet, { loading: loadL, error: errL }] = useMutation(LOAD_SHEET);
  const [resetSheet, { loading: loadR, error: errR }] = useMutation(
    RESET_SHEET
  );
  const [startProject, { loading: LoadS, error: errS }] = useMutation(
    START_PROJECT
  );
  const [endProject, { loading: LoadE, error: errE }] = useMutation(
    END_PROJECT
  );
  loading = loading || loadL || loadR || LoadS || LoadE;
  error = error || errL || errR || errS || errE;
  return (
    <RenderResponse
      loading={loading}
      error={error}
      data={data}
      active={active}
      functs={{ get: refetch, load: loadSheet, reset: resetSheet }}
      proj={{ start: startProject, end: endProject }}
      change={change}
    />
  );
}

const GET_USER = gql`
  query($userId: ID!) {
    getUser(id: $userId) {
      _id
      sheets {
        _id
        title
        active
        projects {
          name
          active
          start
          value
          loaded
        }
      }
    }
  }
`;
const LOAD_SHEET = gql`
  mutation($userId: ID!, $sheetIndex: Int!) {
    loadSheet(userId: $userId, sheetIndex: $sheetIndex) {
      _id
      projects {
        value
        loaded
      }
    }
  }
`;

const RESET_SHEET = gql`
  mutation($userId: ID!, $sheetIndex: Int!) {
    resetSheet(userId: $userId, sheetIndex: $sheetIndex) {
      _id
      title
      active
      projects {
        name
        active
        start
        value
        loaded
      }
    }
  }
`;

const START_PROJECT = gql`
  mutation($userId: ID!, $sheetIndex: Int!, $projectIndex: Int!) {
    startProject(
      userId: $userId
      sheetIndex: $sheetIndex
      projectIndex: $projectIndex
    ) {
      _id
      sheets {
        _id
        active
        projects {
          active
          start
        }
      }
    }
  }
`;

const END_PROJECT = gql`
  mutation($userId: ID!, $sheetIndex: Int!, $projectIndex: Int!) {
    endProject(
      userId: $userId
      sheetIndex: $sheetIndex
      projectIndex: $projectIndex
    ) {
      _id
      active
      projects {
        active
        start
        value
        loaded
      }
    }
  }
`;
