import React, { Fragment } from "react";
import Loading from "./Loading";
import Projects from "./Projects";
import Sheet from "./Sheet";
import Error from "./Error";
import Buttons from "./Buttons";

export default function RenderResponse({
  loading,
  error,
  data,
  active,
  functs,
  proj,
  change,
}) {
  if (loading) return <Loading />;
  if (error) return <Error msg={error.msg} />;
  if (!data.getUser) return <Error msg={"No user with this URL found!"} />;
  const sheets = data.getUser.sheets;
  const userId = data.getUser._id;
  if (sheets.length === 1)
    return (
      <Fragment>
        <Buttons
          functs={functs}
          sheet={sheets[0]}
          sheetIndex={0}
          userId={userId}
        />
        <div
          className="mt-3 d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "80vh", width: "100vw" }}
        >
          <Projects
            projects={sheets[0].projects}
            funcs={proj}
            sheetIndex={0}
            userId={userId}
          />
        </div>
      </Fragment>
    );
  else {
    return (
      <Fragment>
        <Buttons
          functs={functs}
          sheet={sheets[active]}
          sheetIndex={0}
          userId={userId}
        />
        <div className="row w-100 d-flex justify-content-center mt-3">
          {sheets.map((sheet, index) => (
            <Sheet
              key={sheet._id}
              sheet={sheet}
              visible={active === index}
              change={change}
              index={index}
            />
          ))}
        </div>
        {active !== null && (
          <div className="row w-100 d-flex justify-content-center mb-5">
            <Projects
              projects={sheets[active].projects}
              funcs={proj}
              sheetIndex={active}
              userId={data.getUser._id}
            />
          </div>
        )}
      </Fragment>
    );
  }
}
