import React from "react";

import "../styles/sheet.css";

function Sheet({ sheet, visible, change, index }) {
  console.log(sheet, visible);
  const { title } = sheet;
  let classes = "card mb-3 rounded-circle sheet-card";
  if (sheet.active) classes += " active";
  else if (visible) classes += " selected";
  return (
    <div className={classes} style={{ height: "12rem", width: "12rem" }}>
      <div className="card-body text-center px-1 d-flex">
        <h4
          onClick={() => change(index)}
          className="font-weight-bold align-self-center justify-self-center"
          style={{ cursor: "pointer" }}
        >
          {title}
        </h4>
      </div>
    </div>
  );
}

export default Sheet;
