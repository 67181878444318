import React, { Component, Fragment } from "react";
import moment from "moment";

class Timer extends Component {
  constructor(props) {
    super(props);
    const { value, active, start } = props.project;
    let time = this.getMoment(active, value, start);
    this.state = {
      value,
      active,
      start,
      time,
      intervalId: null,
    };
  }

  componentDidMount() {
    if (this.state.active) {
      var intervalId = setInterval(() => this.timer(), 1000);
      this.setState({ intervalId: intervalId });
    }
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  getMoment(active, value, start) {
    if (active) {
      return moment().add(value, "h").subtract(start, "ms").utc();
    } else {
      return moment(0)
        .seconds(value * 60 * 60)
        .utc();
    }
  }

  timer() {
    const { active, value, start } = this.state;
    let sec = this.getMoment(active, value, start);
    this.setState({ time: sec });
    return sec;
  }

  render() {
    return <Fragment>{this.state.time.format("HH:mm:ss")}</Fragment>;
  }
}

export default Timer;
