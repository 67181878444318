import React from "react";

export default function Error({ msg }) {
  if (msg) {
    return (
      <div
        className="d-flex align-items-center"
        style={{ position: "absolute", height: "100vh" }}
      >
        <h4 className="text-center">Error:</h4>
        <h4 className="text-center">{msg}</h4>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex align-items-center"
        style={{ position: "absolute", height: "100vh" }}
      >
        <h4 className="text-center">
          Something went wrong!
          <br />
          please check your connection
        </h4>
      </div>
    );
  }
}
