import React from "react";
import "./App.css";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Sheets from "./components/Sheets";
import Default from "./components/Default";

const client = new ApolloClient({
  uri: "/graphql",
});
function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div
          className="top d-flex flex-column align-items-center pb-3"
          style={{ width: "100vw", height: "100%", minHeight: "100vh" }}
        >
          <Route exact path="/:userId" component={Sheets} />
          <Route exact path="/" component={Default} />
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
