import React from "react";

export default function Default() {
  return (
    <div
      className="d-flex align-items-center"
      style={{ position: "absolute", height: "100vh" }}
    >
      <h3 className="p-5 row">Please use your URL to user TimeCounter</h3>
    </div>
  );
}
